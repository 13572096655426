import React from "react"
import tw, { styled } from "twin.macro"

const OuterWrapper = styled.section`
    ${tw`w-full mx-0 p-0`}
`

const OuterContainer = styled.div`
    ${tw`container w-11/12 mx-auto px-5 py-8`}
`

const Wrapper = styled.div`
    ${tw`w-full`}
`

const Container = styled.div`
    ${tw``}
`

const Section = ({ children }) => {
    return(
        <OuterWrapper>
            <OuterContainer>
                <Wrapper>
                    <Container>
                        {children}
                    </Container>
                </Wrapper>
            </OuterContainer>
        </OuterWrapper>
    )
}

export default Section