import React from "react"
import { Link, StaticQuery, graphql } from "gatsby"
import tw, { styled } from "twin.macro"
import Img from "gatsby-image"

const Wrapper = styled.header`
    ${tw`w-full top-0 left-0 m-0 p-0 bg-psb-dark-gray-400 text-white`}
`

const Container = styled.div`
    ${tw`container w-11/12 mx-auto px-5`}
`

const Row = styled.div`
    ${tw`flex flex-row h-20 items-center`}
`

const Logo = styled.div`
    ${tw`flex flex-row flex-1 items-center overflow-x-auto overflow-y-hidden`}
`

const Title = styled.div`
    ${tw`text-white text-xl md:text-2xl lg:text-3xl xl:text-4xl font-bold underline hidden md:block text-3xl`}
    text-decoration-color: #ff9f0A;
`

const Nav = styled.nav`
    ${tw`flex flex-row flex-1 text-center overflow-x-auto overflow-y-hidden items-baseline justify-end py-4`}
`

const NavList = styled.ul`
    ${tw`list-none inline-block`}
`

const NavLink = styled.li`
    ${tw`float-left`}
`

const StyledLink = styled(Link)`
    ${tw`mx-2 px-3 py-2 rounded-lg hover:bg-psb-dark-light-gray-400 focus:outline-none focus:bg-psb-dark-light-gray-400 transition-colors duration-300 text-base md:text-lg lg:text-xl text-white font-bold`}
`

const Header = ({ data }) => {
    return(
        <Wrapper>
            <Container>
                <Row>
                    <Link to="/">
                        <Logo>
                            <StaticQuery
                                query={graphql`
                                    query {
                                        logo: file(relativePath: { eq: "logo.png" }) {
                                            childImageSharp {
                                                fixed(height: 60 quality: 100) {
                                                    ...GatsbyImageSharpFixed_withWebp
                                                }
                                            }
                                        }
                                    }
                                `}
                                render={data => (
                                    <Img fixed={data.logo.childImageSharp.fixed} alt="Pocket Shutterbug Logo" />
                                )}
                            />
                            <Title>Pocket Shutterbug</Title>
                        </Logo>
                    </Link>
                    <Nav>
                        <NavList>
                            <NavLink>
                                <StyledLink
                                    to="/articles/"
                                    activeStyle={{ backgroundColor: "rgba(255,159,10,var(--bg-opacity))"}}
                                    partiallyActive={true}>
                                    Articles
                                </StyledLink>
                            </NavLink>
                            <NavLink>
                                <StyledLink
                                    to="/guides/"
                                    activeStyle={{ backgroundColor: "rgba(255,159,10,var(--bg-opacity))"}}
                                    partiallyActive={true}>
                                    Guides
                                </StyledLink>
                            </NavLink>
                        </NavList>
                    </Nav>
                </Row>
            </Container>
        </Wrapper>
    )
}

export default Header