import React from "react"
import { Link } from "gatsby"
import tw, { styled } from "twin.macro"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEnvelope } from "@fortawesome/free-regular-svg-icons"
import { faInstagram, faTwitter } from "@fortawesome/free-brands-svg-icons"

const Wrapper = styled.footer`
    ${tw`w-full top-0 left-0 bg-psb-dark-gray-400 text-white m-0 p-0`}
`

const Container = styled.div`
    ${tw`container w-11/12 flex flex-col items-center mx-auto px-5`}
`

const SocialLinks = styled.div`
    ${tw`flex flex-row`}
`

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
    ${tw`m-4 text-psb-orange-400 text-3xl`}
`

const Notices = styled.div`
    ${tw`space-x-3 py-2 text-sm md:text-base`}
`

const SiteLinks = styled.div`
    ${tw`space-x-3 py-2 font-semibold text-base md:text-lg`}
`

const StyledLink = styled(Link)`
    ${tw`text-white`}
`

const NoticesDivider = styled.span`
    ${tw`text-psb-orange-400`}
`

const Copyright = styled.div`
    ${tw`text-sm text-psb-orange-400 py-2`}
`

const Footer = ({ children }) => {
    return(
        <Wrapper>
            <Container>
                <SocialLinks>
                    <a href="mailto:hello@pocketshutterbug.com?subject=Hi%20Pocket%20Shutterbug!">
                        <StyledFontAwesomeIcon icon={faEnvelope} />
                    </a>
                    <a href="https://twitter.com/PShutterbug" target="_blank" rel="noopener noreferrer">
                        <StyledFontAwesomeIcon icon={faTwitter} />
                    </a>
                    <a href="https://www.instagram.com/pocket.shutterbug" target="_blank" rel="noopener noreferrer">
                        <StyledFontAwesomeIcon icon={faInstagram} />
                    </a>
                </SocialLinks>
                <SiteLinks>
                    <StyledLink to="/">Home</StyledLink>
                        <NoticesDivider>:</NoticesDivider>
                    <StyledLink to="/about/">About</StyledLink>
                        <NoticesDivider>:</NoticesDivider>
                    <StyledLink to="/contact/">Contact</StyledLink>
                </SiteLinks>
                <Notices>
                    <StyledLink to="/privacy-policy/">Privacy Policy</StyledLink>
                        <NoticesDivider>:</NoticesDivider>
                    <StyledLink to="/terms-and-conditions/">Terms and Conditions</StyledLink>
                        <NoticesDivider>:</NoticesDivider>
                    <StyledLink to="/disclaimer/">Disclaimer</StyledLink>
                        <NoticesDivider>:</NoticesDivider>
                    <StyledLink to="/cookie-policy/">Cookie Policy</StyledLink>
                </Notices>
                <Copyright>Copyright © 2020 Neon Pixel Studios. All Rights Reserved.</Copyright>
            </Container>
        </Wrapper>
    )
}

export default Footer