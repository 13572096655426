import React from "react"
import { Link } from "gatsby"
import { Global, css } from "@emotion/core"
import tw, { styled } from "twin.macro"

// This ensures that the icon CSS is loaded immediately before attempting to render icons
import "@fortawesome/fontawesome-svg-core/styles.css";
import { config } from "@fortawesome/fontawesome-svg-core";

import CookieConsent from "react-cookie-consent"

import "typeface-quicksand"

import Header from "./header.js"
import Footer from "./footer.js"

const Wrapper = styled.div`
    ${tw`flex flex-col m-0 p-0`}
`

const Container = styled.div`
    ${tw``}
`

const Main = styled.main`
    ${tw`w-full flex flex-col flex-grow flex-shrink-0 m-0 p-0`}
`

const StyledLink = styled(Link)`
    ${tw`text-white font-bold underline`}
`

const Layout = ({ children }) => {
    // Prevent fontawesome from dynamically adding its css since we did it manually above
    config.autoAddCss = false
    return (
        <Wrapper>
            <CookieConsent
                style={{ background: "#4299e1", color: "#ffffff" }}
                buttonStyle={{ background: "#4299e1", color: "#ffffff", fontWeight: 600, border: "2px solid #ffffff", borderRadius: "10px" }}
            >This website uses cookies. By continuing to browse the site you are agreeing to our use of cookies.&nbsp;
            <StyledLink to="/cookie-policy/">Find out more</StyledLink></CookieConsent>
             <Global
                styles={css`
                    body {font-family: "quicksand"; font-weight: 300;}
                    body {${tw`bg-psb-dark-black-400`}}
                    h1 {${tw`text-white text-2xl md:text-3xl lg:text-4xl xl:text-5xl font-bold leading-snug`}}
                    h2 {${tw`text-white text-xl md:text-xl lg:text-2xl xl:text-3xl font-semibold leading-snug pt-4`}}
                    h3 {${tw`text-lg md:text-xl lg:text-2xl font-semibold pt-3 leading-snug text-white`}}
                    h4 {${tw`text-lg md:text-xl font-semibold pt-3 leading-snug text-white`}}
                    p {${tw`leading-relaxed text-base md:text-lg lg:text-xl py-3 font-normal text-white`}}
                    blockquote {${tw`bg-orange-200 border-l-8 rounded-md border-orange-600 my-2 p-4`}}
                    ul {${tw`list-disc list-inside text-base md:text-lg pl-8 font-normal leading-relaxed`}}
                    li {${tw`ml-4 text-white`} text-indent: -16px;}
                    a {${tw`text-psb-orange-400 font-semibold`}}
                    figcaption {${tw`flex justify-center text-sm text-psb-dark-light-gray-400 m-1`}}
                `}
            />
            <Container>
                <Header />
                <Main>{children}</Main>
                <Footer />
            </Container>
        </Wrapper>
    )
}

export default Layout